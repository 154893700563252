import http from "../http-common";

class TherapyService {
    async getAll(tags){
        this.abortControllerGetAll = new AbortController();
        // N.B.: if tags is null, it will be ignored, i.e. undefined at backend
        const result = await http.get('/therapies', {
            // FIXME JSON.stringify is probably redundant, remove, also the respective parse in the backend
            params: {tags: JSON.stringify(tags),
            signal: this.abortControllerGetAll.signal}});
        this.abortControllerGetAll = null;
        return result;
    }
    abortGetAll(){
        if (this.abortControllerGetAll)
            this.abortControllerGetAll.abort();
        this.abortControllerGetAll = null;
    }
    getAllForRegistration(){
        return http.get('/therapies/forRegistration');
    }
    get(id){
        return http.get(`/therapies/therapyId/${id}`);
    }
    getTableOfContents(id, userId){
        return http.get(`/therapies/${id}/tableOfContents`,
            {params: {userId}});
    }
    getSectionStatistics(id, userId){
        return http.get(`/therapies/${id}/sectionStatistics`,
            {params: {userId}});
    }
    getSectionTracking(id, userId){
        return http.get(`/therapies/${id}/sectionTracking`,
            {params: {userId}});
    }
    update(therapy){
        const id=therapy._id;
        return http.put(`/therapies/${id}`, {therapy});
    }
    delete(id){
        return http.delete(`/therapies/${id}`);
    }
    duplicate(id){
        return http.post(`/therapies/duplicate/${id}`, 
            {});
    }
    create(){
        return http.post('/therapies/create');
    }
    getUsingBook(bookId){
        return http.get(`/therapies/usingBook/${bookId}`);
    }
    getUsingTagOne(tagId){
        return http.get(`/therapies/usingTagOne/${tagId}`);
    }
    getInfo(){
        return http.get(`/therapies/info`);
    }
    getSlow1(id){
        return http.get('/therapies/slow1');
    }
    getSlow2(id){
        return http.get('/therapies/slow2');
    }
    getWeeklyReportVariables(){
        return http.get('/therapies/weeklyReportVariables');
    }
    getMonthlyReportVariables(){
        return http.get('/therapies/monthlyReportVariables');
    }
}

export default new TherapyService();

